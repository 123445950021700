<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.BrukerID"
        item-text="Description"
        item-value="Value"
        :items="BrukerItems"
        label="Bruker"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.ProsjektStatusID"
        item-text="Description"
        item-value="Value"
        :items="ProsjektStatusItems"
        label="Prosjekt status"
      ></v-autocomplete>
      <v-text-field
        v-model="editedItem.ProsjektNavn"
        label="Prosjekt navn"
        maxlength="120"
      ></v-text-field>
      <v-textarea
        v-model="editedItem.Beskrivelse"
        filled
        label="Beskrivelse"
        auto-grow
      ></v-textarea>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";

export default {
  extends: KeyValue,
  name: "dlgProsjekt",
  components: {},

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyFirmaID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetBrukerCode();
    this.GetProsjektStatusCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
